<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.35 226.03">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path class="cls-1" d="m47.63,222.53c29.47-59.23,67.96-129.63,134.16-170.76" />
        <path class="cls-1" d="m63.51,189.9C60.51,185.89,9.36,49.34,218.86,3.5c-4.72,181.74-124.45,194-142.99,193.11" />
        <path
          class="cls-1"
          d="m49.39,186.77C23.32,166.51,3.5,136.5,3.5,97.37c0-26.82,5.3-50.07,15-61.69,18.54,7.15,36.19,29.5,41.48,48.28"
        />
        <path class="cls-1" d="m28.21,72.34s4.41,38.44,26.48,71.52" />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  fill: none;
  stroke: #267891;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 6.99px;
}
</style>
